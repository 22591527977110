import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=524fcd84&"
import script from "./ContactForm.vue?vue&type=script&lang=js&"
export * from "./ContactForm.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "2eff5fc2"
  
)

export default component.exports