<template>
  <div>
    <div id="collapseMap" class="banner collapse">
      <!-- <no-ssr>
        <GmapMap
          :center="coordinates"
          :zoom="17"
          map-type-id="roadmap"
          style="width: 100%; height: 450px"
        >
          <GmapMarker
            :position="coordinates"
            :clickable="true"
            :draggable="false"
            @click="onMarkerClicked"
          />
        </GmapMap>
      </no-ssr> -->
    </div>
    <section class="main-container">
      <div class="container">
        <div class="row">
          <contact-form />
          <contact-side-bar />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from "../components/ContactForm.vue";
import ContactSideBar from "../components/ContactSideBar.vue";

// import { data } from "../data/data.js";

// const addresses = data.aboutMe.addresses;

export default {
  components: { ContactForm, ContactSideBar },
  // data() {
  //   return {
  //     coordinates: address.coordinates
  //   };
  // },
  metaInfo: {
    title: "İletişim"
  },
  methods: {
    // onMarkerClicked() {
    //   window.open(address.mapsAddress, "_blank");
    // }
  }
};
</script>

<style></style>
