<template>
  <aside class="col-lg-4 col-xl-3 ml-xl-auto contact-side-bar">
    <div class="sidebar">
      <div class="block clearfix">
        <h3 class="title">Adres</h3>
        <div class="separator-2"></div>
        <ul class="list">
          <li v-for="(address, index) in aboutMe.addresses" :key="index">
            <i class="fa fa-home pr-10"></i>{{ address.text }}
          </li>
          <li>
            <i class="fa fa-mobile pr-10 pl-1"></i
            ><abbr title="Phone">M:</abbr> {{ aboutMe.phone }}
          </li>
          <li>
            <i class="fa fa-envelope pr-10"></i
            ><a :href="`mailto:${aboutMe.email}`">{{ aboutMe.email }}</a>
          </li>
        </ul>
        <a
          class="btn btn-gray collapsed map-show btn-animated"
          data-toggle="collapse"
          href="#collapseMap"
          aria-expanded="false"
          aria-controls="collapseMap"
          >Haritayı Göster <i class="fa fa-plus"></i
        ></a>
      </div>
    </div>
    <div class="sidebar">
      <div class="block clearfix">
        <h2 class="title">Linkler</h2>
        <div class="separator-2"></div>
        <ul
          class="social-links circle small margin-clear clearfix animated-effect-1"
        >
          <li class="facebook">
            <a :href="aboutMe.facebook"><i class="fa fa-facebook"></i></a>
          </li>
          <li class="linkedin">
            <a :href="aboutMe.linkedin"><i class="fa fa-linkedin"></i></a>
          </li>
          <li>
            <a class="armut" :href="aboutMe.armutcom"
              ><i class="fa fa-armut"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
import { data } from "../data/data.js";

export default {
  data() {
    return {
      aboutMe: data.aboutMe
    };
  }
};
</script>

<style></style>
