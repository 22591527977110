<template>
  <div class="main col-lg-8">
    <h1 class="page-title">İLETİŞİM</h1>
    <div class="separator-2"></div>
    <p>
      Soru ve görüşleriniz için iletişim formunu kullanarak bize
      ulaşabilirsiniz.
    </p>
    <div class="contact-form">
      <form class="margin-clear" @submit="validateForm">
        <div class="form-row">
          <div class="form-group has-feedback col-md-6">
            <input
              id="name"
              v-model="name"
              type="text"
              class="form-control"
              placeholder="Adınız soyadınız"
              :class="{ 'is-invalid': attemptSubmit && missingName }"
            />
            <div class="invalid-feedback">Lütfen adınızı giriniz.</div>
          </div>
          <div class="form-group has-feedback col-md-6">
            <input
              id="email"
              v-model="email"
              type="text"
              class="form-control"
              placeholder="Email adresiniz"
              :class="{ 'is-invalid': attemptSubmit && invalidEmail }"
            />
            <div class="invalid-feedback">
              Lütfen geçerli bir email adresi giriniz.
            </div>
          </div>
        </div>
        <div class="form-group has-feedback">
          <input
            id="subject"
            v-model="subject"
            type="text"
            class="form-control"
            placeholder="Konu"
            :class="{ 'is-invalid': attemptSubmit && missingSubject }"
          />
          <div class="invalid-feedback">Lütfen bir konu giriniz.</div>
        </div>
        <div class="form-group has-feedback">
          <textarea
            id="message"
            v-model="message"
            class="form-control"
            rows="6"
            placeholder="Mesaj"
            :class="{ 'is-invalid': attemptSubmit && missingMessage }"
          ></textarea>
          <div class="invalid-feedback">Lütfen mesajınızı giriniz.</div>
        </div>
        <button
          type="submit"
          value="Gönder"
          class="submit-button btn btn-lg btn-default"
        >
          Gönder
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      attemptSubmit: false
    };
  },
  computed: {
    missingName() {
      return this.name === "";
    },
    invalidEmail() {
      return this.email === "" || !/(^\w.*@\w+\.\w)/.test(this.email);
    },
    missingSubject() {
      return this.subject === "";
    },
    missingMessage() {
      return this.message === "";
    }
  },
  methods: {
    validateForm(event) {
      this.attemptSubmit = true;
      if (
        this.missingName ||
        this.invalidEmail ||
        this.missingSubject ||
        this.missingMessage
      ) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style></style>
